import React from 'react';

import moment from 'moment-timezone';
import _ from 'lodash';
import { _bulk, _time, _user } from 'std';

import { useHistory } from 'react-router-dom';

import { formatAsCurrency, deviceHelper, isEXPRegion, getQuickDropName } from 'utils/misc';

import {
    Button,
    Grid,
    Paper,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    List,
    ListItem,
    Checkbox,
    ListItemText,
    Typography,
    CircularProgress,
    Icon,
    Switch,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import { colors } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import CollectorDashboardDropDown from 'components/DropDownSelects/CollectorDashboardDropDown';

import useBulkStats from './hooks/useBulkStats';
import bulkHelper from 'helpers/bulkHelper';
import { useState } from 'react';

function BulkStats({ theme, collectors, receivers, operator, initialCollector, pickupsEnabled }) {
    const history = useHistory();
    const [hoverRow, setHoverRow] = useState('');
    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const {
        counters,
        bulkList,
        loading,
        collectorsSelected,
        groupBy,
        setGroupBy,
        orderTypesSelected,
        handleToggleCountersSelected,
        handleExportBulkStats,
        handleFetchReport,
        handleCollectorsSelected,
        handleAllFilterCollectors,
        getMaterialList,
        handleOrderTypesSelected,
        isSchemeIDUser,
        handleSchemeMemberfilter,
        groupByCountDate,
        setGroupByCountDate
        // getCollectorNameFromCollectors
    } = useBulkStats({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        collectors,
        initialCollector: _user.isCollectorAdmin(operator) ? initialCollector : null,
        pickupsEnabled
    });

    const handleViewBulk = bulk => {
        if (deviceHelper.isNativeApp()) {
            history.push(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`);
        } else {
            window.open(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`, '_blank');
        }
    };
    const countersStats = _(counters)
        .filter(c => c.selected)
        .value();

    const [countersStatTotals, setCountersStatTotals] = useState([]);
    const [bulkListTotals, setBulkListTotals] = useState([]);
    const [materialList, setMaterialList] = useState([]);
    const [amountMaterialList, setAmountMaterialList] = useState([]);
    const [materialToSkuMap, setMaterialToSkuMap] = useState({});
    React.useEffect(() => {
        let { newMaterialList, newAmountMaterialList, materialToSkuMap } = getMaterialList(countersStats);
        setMaterialList(newMaterialList);
        setAmountMaterialList(newAmountMaterialList);
        setMaterialToSkuMap(materialToSkuMap);
        let newCountersStatTotals = {
            totalBulkNumbers: 0,
            totalBulksItemCounts: 0,
            totalBulksValue: 0,
            totalBulkItemCountsBySize: {},
            totalBulkValueBySize: {},
            totalBulksFee: 0
        };
        for (let i = 0; i < newMaterialList.length; i++) {
            let material = newMaterialList[i];
            newCountersStatTotals.totalBulkItemCountsBySize[material] = 0;
            newCountersStatTotals.totalBulkValueBySize[material] = 0;
        }
        for (let i = 0; i < countersStats.length; i++) {
            let currentCounter = countersStats[i];
            newCountersStatTotals.totalBulkNumbers += currentCounter.bulks.length;
            newCountersStatTotals.totalBulksItemCounts += currentCounter.totalBulksItemCounts;
            newCountersStatTotals.totalBulksValue += currentCounter.totalBulksValue;
            newCountersStatTotals.totalBulksFee += currentCounter.totalBulksFee;

            for (let i = 0; i < newMaterialList.length; i++) {
                let material = newMaterialList[i];
                newCountersStatTotals.totalBulkItemCountsBySize[material] += _.get(
                    currentCounter,
                    `totalBulkItemCountsBySize[${material}]`,
                    0
                );
                newCountersStatTotals.totalBulkValueBySize[material] += _.get(
                    currentCounter,
                    `totalBulkValueBySize[${material}]`,
                    0
                );
            }
        }
        setCountersStatTotals(newCountersStatTotals);
    }, [counters]);
    React.useEffect(() => {
        let { newMaterialList, newAmountMaterialList, materialToSkuMap } = getMaterialList(countersStats);
        setMaterialList(newMaterialList);
        setAmountMaterialList(newAmountMaterialList);
        setMaterialToSkuMap(materialToSkuMap);
        let newBulkListTotals = {
            totalBulkNumbers: 0,
            totalBulkItemCounts: 0,
            totalBulkValue: 0,
            totalBulkItemCountsBySize: {},
            totalBulkValueBySize: {}
        };
        for (let i = 0; i < newMaterialList.length; i++) {
            let material = newMaterialList[i];
            newBulkListTotals.totalBulkItemCountsBySize[material] = 0;
            newBulkListTotals.totalBulkValueBySize[material] = 0;
        }
        for (let i = 0; i < bulkList.length; i++) {
            let currentBulk = bulkList[i];
            let stateKeys = Object.keys(currentBulk.reportStats);
            newBulkListTotals.totalBulkNumbers += stateKeys.length;
            newBulkListTotals.totalBulkItemCounts += currentBulk.totalBulkItemCounts;
            newBulkListTotals.totalBulkValue += currentBulk.totalBulkValue;

            for (let i = 0; i < newMaterialList.length; i++) {
                let material = newMaterialList[i];
                newBulkListTotals.totalBulkItemCountsBySize[material] += _.get(
                    currentBulk,
                    `totalBulkItemCountsBySize[${material}]`,
                    0
                );
                newBulkListTotals.totalBulkValueBySize[material] += _.get(
                    currentBulk,
                    `totalBulkValueBySize[${material}]`,
                    0
                );
            }
        }
        setBulkListTotals(newBulkListTotals);
    }, [bulkList]);
    const feeInAllBulks = _.sum(bulkList.map(b => _.get(b, 'totalBulkFee', 0)));
    const counterTableCellStyle = { paddingRight: 10 };
    const bulkTableCellStyle = { paddingRight: 10 };
    return (
        <>
            <Grid style={{ fontFamily: theme.typography.fontFamily }}>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                                <FormControl
                                    style={{
                                        marginTop: 10,
                                        marginRight: 10
                                    }}
                                >
                                    <InputLabel variant="outlined" htmlFor="timeWindow-filter">
                                        {'Time Window'}
                                    </InputLabel>
                                    <Select
                                        disabled={loading}
                                        value={dateWindow}
                                        onChange={handleWindowSelect}
                                        inputProps={{
                                            name: 'timeWindow-filter',
                                            id: 'timeWindow-filter'
                                        }}
                                        input={
                                            <OutlinedInput
                                                labelWidth={100}
                                                name="timeWindow-filter"
                                                id="timeWindow-filter"
                                            />
                                        }
                                        style={{
                                            minWidth: 110
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {!isEXPRegion() && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={groupByCountDate}
                                                onChange={() => setGroupByCountDate(groupByCountDate ? false : true)}
                                            />
                                        }
                                        labelPlacement="start"
                                        label={'Breakout Count Date'}
                                        style={{ margin: 0 }}
                                    />
                                )}
                                {!isEXPRegion() && (
                                    <FormControl
                                        style={{
                                            marginTop: 10,
                                            marginRight: 10
                                        }}
                                    >
                                        <InputLabel htmlFor="Group-by" variant="outlined">
                                            Group by
                                        </InputLabel>
                                        <Select
                                            variant="outlined"
                                            value={groupBy}
                                            onChange={e => {
                                                setGroupBy(e.target.value);
                                            }}
                                            style={{ width: 110 }}
                                            input={<OutlinedInput labelWidth={80} />}
                                        >
                                            <MenuItem value={'Counter'}>Counter</MenuItem>
                                            <MenuItem value={'Bulk'}>Order</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}

                                <FormControl
                                    style={{
                                        marginTop: 10,
                                        marginRight: 10
                                    }}
                                >
                                    <InputLabel htmlFor="isSchemeIDUser" variant="outlined">
                                        Scheme Member
                                    </InputLabel>
                                    <Select
                                        variant="outlined"
                                        value={isSchemeIDUser}
                                        onChange={handleSchemeMemberfilter}
                                        style={{ width: 110 }}
                                        input={<OutlinedInput labelWidth={80} />}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl
                                    style={{
                                        marginTop: 10,
                                        marginRight: 10
                                    }}
                                >
                                    <InputLabel variant="outlined" htmlFor="orderType-filter">
                                        {'Order Type'}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        data-cy="bulk-type-selection-dropdown"
                                        value={orderTypesSelected}
                                        onChange={handleOrderTypesSelected}
                                        disabled={loading}
                                        inputProps={{
                                            name: 'orderType-filter',
                                            id: 'orderType-filter'
                                        }}
                                        input={
                                            <OutlinedInput
                                                labelWidth={76}
                                                name="orderType-filter"
                                                id="orderType-filter"
                                            />
                                        }
                                        style={{
                                            minWidth: 110
                                        }}
                                        renderValue={s => `${s.length} ${'types'}`}
                                    >
                                        {pickupsEnabled && (
                                            <MenuItem
                                                key="pickup"
                                                value="pickup"
                                                disabled={loading}
                                                data-cy="bulk-type-selection-dropdown-pickup"
                                            >
                                                <Checkbox checked={orderTypesSelected.includes('pickup')} />
                                                <ListItemText>Pickup</ListItemText>
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            key="inhouse"
                                            value="inhouse"
                                            disabled={loading}
                                            data-cy="bulk-type-selection-dropdown-inhouse"
                                        >
                                            <Checkbox checked={orderTypesSelected.includes('inhouse')} />
                                            <ListItemText>{getQuickDropName('en')}</ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            key="walk-in"
                                            value="walk-in"
                                            disabled={loading}
                                            data-cy="bulk-type-selection-dropdown-walk-in"
                                        >
                                            <Checkbox checked={orderTypesSelected.includes('walk-in')} />
                                            <ListItemText>Walk In</ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            key="adjustment"
                                            value="adjustment"
                                            disabled={loading}
                                            data-cy="bulk-type-selection-dropdown-adjustment"
                                        >
                                            <Checkbox checked={orderTypesSelected.includes('adjustment')} />
                                            <ListItemText>Adjustment</ListItemText>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <CollectorDashboardDropDown
                                    id="collector-dropdown"
                                    loading={loading}
                                    value={collectors}
                                    collectors={collectors}
                                    collectorsSelected={collectorsSelected}
                                    cypress_id="trip-list-collector-dropdown"
                                    handleCollectorsSelected={handleCollectorsSelected}
                                    handleAllFilterCollectors={handleAllFilterCollectors}
                                    label="Processors"
                                    labelCustomWidth={80}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    data-cy="generate-clerk-report"
                                    onClick={handleFetchReport}
                                    disabled={loading}
                                >
                                    <CircularProgress
                                        size={22}
                                        thickness={4.8}
                                        style={{
                                            display: loading ? '' : 'none'
                                        }}
                                    />
                                    <Icon style={{ display: loading ? 'none' : '' }}>search</Icon>
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={_.isEmpty(countersStats)}
                                    onClick={handleExportBulkStats}
                                >
                                    Export CSV
                                </Button>
                            </div>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item sm={8} xs={12}>
                                    <Typography variant="h6">Clerk Report</Typography>
                                    <div
                                        style={{
                                            border: `2px solid ${theme.palette.grey[200]}`,
                                            borderRadius: 4,
                                            padding: theme.spacing.unit,
                                            height: 600
                                        }}
                                    >
                                        {groupBy === 'Counter' && (
                                            <table
                                                data-cy="operator-clerk-report-table"
                                                style={{
                                                    display: 'flex',
                                                    overflow: 'auto',
                                                    'white-space': 'nowrap',
                                                    marginTop: theme.spacing.unit,
                                                    flexDirection: 'row',
                                                    height: 600,
                                                    color: theme.palette.text.primary,
                                                    borderCollapse: 'collapse'
                                                }}
                                            >
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={counterTableCellStyle}>Counter</td>
                                                            <td style={counterTableCellStyle}>Label</td>
                                                            <td style={counterTableCellStyle}>Account</td>
                                                            <td style={counterTableCellStyle}>Type</td>
                                                            {groupByCountDate && (
                                                                <td style={counterTableCellStyle}>Count Date</td>
                                                            )}
                                                            <td style={counterTableCellStyle}>Date Completed</td>
                                                            <td style={counterTableCellStyle}>Processor</td>
                                                        </tr>
                                                        {countersStats.map(c => {
                                                            return (
                                                                <React.Fragment key={c._id}>
                                                                    {groupByCountDate
                                                                        ? c.bulks.map(b => {
                                                                              const reportStatsByDate = _.get(
                                                                                  b,
                                                                                  `reportStatsByDate.${c._id}`,
                                                                                  {}
                                                                              );
                                                                              return Object.keys(reportStatsByDate).map(
                                                                                  (date, index) => {
                                                                                      return (
                                                                                          <tr
                                                                                              key={c._id + b._id + date}
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              <td>&nbsp;</td>
                                                                                              <td
                                                                                                  style={
                                                                                                      counterTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  <span
                                                                                                      style={{
                                                                                                          color:
                                                                                                              theme
                                                                                                                  .palette
                                                                                                                  .primary
                                                                                                                  .main,
                                                                                                          cursor:
                                                                                                              'pointer'
                                                                                                      }}
                                                                                                      onClick={() => {
                                                                                                          handleViewBulk(
                                                                                                              b
                                                                                                          );
                                                                                                      }}
                                                                                                  >
                                                                                                      {`${_bulk.getTruncatedLabel(
                                                                                                          b
                                                                                                      )}-${index + 1}`}
                                                                                                  </span>
                                                                                              </td>
                                                                                              {_.get(
                                                                                                  b,
                                                                                                  'isSchemeIDUser',
                                                                                                  false
                                                                                              ) ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          counterTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      CDS
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              )}
                                                                                              <td
                                                                                                  style={
                                                                                                      counterTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  {_.capitalize(
                                                                                                      bulkHelper.getBulkTypeFormatted(
                                                                                                          b
                                                                                                      )
                                                                                                  )}
                                                                                              </td>
                                                                                              <td
                                                                                                  style={
                                                                                                      counterTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  {moment(date).format(
                                                                                                      'll'
                                                                                                  )}
                                                                                              </td>
                                                                                              <td
                                                                                                  style={
                                                                                                      counterTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  {moment(
                                                                                                      b.dateCompleted
                                                                                                  ).format('lll')}
                                                                                              </td>
                                                                                              <td
                                                                                                  style={
                                                                                                      counterTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  {_.get(
                                                                                                      b,
                                                                                                      'collector.name',
                                                                                                      ''
                                                                                                  )}
                                                                                              </td>
                                                                                          </tr>
                                                                                      );
                                                                                  }
                                                                              );
                                                                          })
                                                                        : c.bulks.map(b => {
                                                                              return (
                                                                                  <tr
                                                                                      key={c._id + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow === c._id + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(c._id + b._id);
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      <td>&nbsp;</td>
                                                                                      <td style={counterTableCellStyle}>
                                                                                          <span
                                                                                              style={{
                                                                                                  color:
                                                                                                      theme.palette
                                                                                                          .primary.main,
                                                                                                  cursor: 'pointer'
                                                                                              }}
                                                                                              onClick={() => {
                                                                                                  handleViewBulk(b);
                                                                                              }}
                                                                                          >
                                                                                              {_bulk.getTruncatedLabel(
                                                                                                  b
                                                                                              )}
                                                                                          </span>
                                                                                      </td>
                                                                                      {_.get(
                                                                                          b,
                                                                                          'isSchemeIDUser',
                                                                                          false
                                                                                      ) ? (
                                                                                          <td
                                                                                              style={
                                                                                                  counterTableCellStyle
                                                                                              }
                                                                                          >
                                                                                              CDS
                                                                                          </td>
                                                                                      ) : (
                                                                                          <td>&nbsp;</td>
                                                                                      )}
                                                                                      <td style={counterTableCellStyle}>
                                                                                          {_.capitalize(
                                                                                              bulkHelper.getBulkTypeFormatted(
                                                                                                  b
                                                                                              )
                                                                                          )}
                                                                                      </td>
                                                                                      <td style={counterTableCellStyle}>
                                                                                          {moment(
                                                                                              b.dateCompleted
                                                                                          ).format('lll')}
                                                                                      </td>
                                                                                      <td style={counterTableCellStyle}>
                                                                                          {_.get(
                                                                                              b,
                                                                                              'collector.name',
                                                                                              ''
                                                                                          )}
                                                                                      </td>
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === c._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(c._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        <td style={counterTableCellStyle}>
                                                                            <strong>
                                                                                {c.name.first} {c.name.last}
                                                                            </strong>
                                                                        </td>
                                                                        <td style={counterTableCellStyle}>
                                                                            <strong>{c.bulks.length} Total</strong>
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>&nbsp;</td>
                                                                        {groupByCountDate && <td>&nbsp;</td>}
                                                                        <td>&nbsp;</td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        {countersStats.length > 0 && (
                                                            <React.Fragment key={'countersStatTotals'}>
                                                                <tr style={{ 'border-top': '1px solid grey' }}>
                                                                    <td>&nbsp;</td>
                                                                    <td style={counterTableCellStyle}>
                                                                        <strong>
                                                                            {countersStatTotals.totalBulkNumbers} Total
                                                                        </strong>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )}
                                                    </tbody>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        Qty
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={counterTableCellStyle}>{`Total`}</td>
                                                            {materialList.map(material => {
                                                                return (
                                                                    <td
                                                                        style={counterTableCellStyle}
                                                                    >{`${material}`}</td>
                                                                );
                                                            })}{' '}
                                                        </tr>
                                                        {countersStats.map(c => {
                                                            return (
                                                                <React.Fragment key={c._id}>
                                                                    {groupByCountDate
                                                                        ? c.bulks.map(b => {
                                                                              const reportStatsByDate = _.get(
                                                                                  b,
                                                                                  `reportStatsByDate.${c._id}`,
                                                                                  {}
                                                                              );
                                                                              return Object.keys(reportStatsByDate).map(
                                                                                  (date, index) => {
                                                                                      const displayAmount = _.get(
                                                                                          reportStatsByDate,
                                                                                          `${date}.quantity`
                                                                                      );
                                                                                      return (
                                                                                          <tr
                                                                                              key={c._id + b._id + date}
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              {displayAmount !== 0 ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          counterTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {displayAmount}
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              )}

                                                                                              {materialList.map(
                                                                                                  material => {
                                                                                                      const displayAmount2 = _.get(
                                                                                                          reportStatsByDate,
                                                                                                          `${date}.${material}.quantity`,
                                                                                                          0
                                                                                                      );
                                                                                                      return displayAmount2 !==
                                                                                                          0 ? (
                                                                                                          <td
                                                                                                              style={
                                                                                                                  counterTableCellStyle
                                                                                                              }
                                                                                                          >
                                                                                                              {
                                                                                                                  displayAmount2
                                                                                                              }
                                                                                                          </td>
                                                                                                      ) : (
                                                                                                          <td>
                                                                                                              &nbsp;
                                                                                                          </td>
                                                                                                      );
                                                                                                  }
                                                                                              )}
                                                                                          </tr>
                                                                                      );
                                                                                  }
                                                                              );
                                                                          })
                                                                        : c.bulks.map(b => {
                                                                              const displayAmount = _.get(
                                                                                  b,
                                                                                  `reportStats.${c._id}.quantity`
                                                                              );
                                                                              return (
                                                                                  <tr
                                                                                      key={c._id + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow === c._id + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(c._id + b._id);
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      {displayAmount !== 0 ? (
                                                                                          <td
                                                                                              style={
                                                                                                  counterTableCellStyle
                                                                                              }
                                                                                          >
                                                                                              {displayAmount}
                                                                                          </td>
                                                                                      ) : (
                                                                                          <td>&nbsp;</td>
                                                                                      )}

                                                                                      {materialList.map(material => {
                                                                                          const displayAmount2 = _.get(
                                                                                              b,
                                                                                              `reportStats.${c._id}.${material}.quantity`,
                                                                                              0
                                                                                          );
                                                                                          return displayAmount2 !==
                                                                                              0 ? (
                                                                                              <td
                                                                                                  style={
                                                                                                      counterTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  {displayAmount2}
                                                                                              </td>
                                                                                          ) : (
                                                                                              <td>&nbsp;</td>
                                                                                          );
                                                                                      })}
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === c._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(c._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        {c.totalBulksItemCounts !== 0 ? (
                                                                            <td style={counterTableCellStyle}>
                                                                                <strong>
                                                                                    {c.totalBulksItemCounts}
                                                                                </strong>
                                                                            </td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                        {materialList.map(material => {
                                                                            const displayAmount =
                                                                                c.totalBulkItemCountsBySize[material];
                                                                            return (
                                                                                <td style={counterTableCellStyle}>
                                                                                    <strong>
                                                                                        {displayAmount === 0
                                                                                            ? ''
                                                                                            : displayAmount}
                                                                                    </strong>
                                                                                </td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        {countersStats.length > 0 && (
                                                            <React.Fragment key={'countersStatTotals'}>
                                                                <tr style={{ 'border-top': '1px solid grey' }}>
                                                                    {countersStatTotals.totalBulksItemCounts !== 0 ? (
                                                                        <td style={counterTableCellStyle}>
                                                                            <strong>
                                                                                {
                                                                                    countersStatTotals.totalBulksItemCounts
                                                                                }
                                                                            </strong>
                                                                        </td>
                                                                    ) : (
                                                                        <td>&nbsp;</td>
                                                                    )}
                                                                    {materialList.map(material => {
                                                                        return countersStatTotals
                                                                            .totalBulkItemCountsBySize[material] !==
                                                                            0 ? (
                                                                            <td style={counterTableCellStyle}>
                                                                                <strong>
                                                                                    {
                                                                                        countersStatTotals
                                                                                            .totalBulkItemCountsBySize[
                                                                                            material
                                                                                        ]
                                                                                    }
                                                                                </strong>
                                                                            </td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            </React.Fragment>
                                                        )}
                                                    </tbody>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        Amount
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={counterTableCellStyle}>{`Total`}</td>
                                                            {amountMaterialList.map(material => {
                                                                return (
                                                                    <td
                                                                        style={counterTableCellStyle}
                                                                    >{`${material}`}</td>
                                                                );
                                                            })}
                                                        </tr>
                                                        {countersStats.map(c => {
                                                            return (
                                                                <React.Fragment key={c._id}>
                                                                    {groupByCountDate
                                                                        ? c.bulks.map(b => {
                                                                              const reportStatsByDate = _.get(
                                                                                  b,
                                                                                  `reportStatsByDate.${c._id}`,
                                                                                  {}
                                                                              );
                                                                              return Object.keys(reportStatsByDate).map(
                                                                                  (date, index) => {
                                                                                      const displayAmount = _.get(
                                                                                          reportStatsByDate,
                                                                                          `${date}.amount`
                                                                                      );

                                                                                      return (
                                                                                          <tr
                                                                                              key={c._id + b._id + date}
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              {displayAmount !== 0 &&
                                                                                              !_.isNil(
                                                                                                  displayAmount
                                                                                              ) ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          counterTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {formatAsCurrency(
                                                                                                          displayAmount
                                                                                                      )}
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              )}

                                                                                              {amountMaterialList.map(
                                                                                                  material => {
                                                                                                      const displayAmount2 = _.get(
                                                                                                          reportStatsByDate,
                                                                                                          `${date}.${material}.amount`,
                                                                                                          0
                                                                                                      );
                                                                                                      return displayAmount2 !==
                                                                                                          0 &&
                                                                                                          !_.isNil(
                                                                                                              displayAmount2
                                                                                                          ) ? (
                                                                                                          <td
                                                                                                              style={
                                                                                                                  counterTableCellStyle
                                                                                                              }
                                                                                                          >
                                                                                                              {formatAsCurrency(
                                                                                                                  displayAmount2
                                                                                                              )}
                                                                                                          </td>
                                                                                                      ) : (
                                                                                                          <td>
                                                                                                              &nbsp;
                                                                                                          </td>
                                                                                                      );
                                                                                                  }
                                                                                              )}
                                                                                          </tr>
                                                                                      );
                                                                                  }
                                                                              );
                                                                          })
                                                                        : c.bulks.map(b => {
                                                                              const displayAmount = _.get(
                                                                                  b,
                                                                                  `reportStats.${c._id}.amount`
                                                                              );
                                                                              return (
                                                                                  <tr
                                                                                      key={c._id + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow === c._id + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(c._id + b._id);
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      {displayAmount !== 0 &&
                                                                                      !_.isNil(displayAmount) ? (
                                                                                          <td
                                                                                              style={
                                                                                                  counterTableCellStyle
                                                                                              }
                                                                                          >
                                                                                              {formatAsCurrency(
                                                                                                  displayAmount
                                                                                              )}
                                                                                          </td>
                                                                                      ) : (
                                                                                          <td>&nbsp;</td>
                                                                                      )}

                                                                                      {amountMaterialList.map(
                                                                                          material => {
                                                                                              const displayAmount2 = _.get(
                                                                                                  b,
                                                                                                  `reportStats.${c._id}.${material}.amount`,
                                                                                                  0
                                                                                              );
                                                                                              return displayAmount2 !==
                                                                                                  0 &&
                                                                                                  !_.isNil(
                                                                                                      displayAmount2
                                                                                                  ) ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          counterTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {formatAsCurrency(
                                                                                                          displayAmount2
                                                                                                      )}
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              );
                                                                                          }
                                                                                      )}
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === c._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(c._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        {c.totalBulksValue !== 0 &&
                                                                        !_.isNil(c.totalBulksValue) ? (
                                                                            <td style={counterTableCellStyle}>
                                                                                <strong>
                                                                                    {formatAsCurrency(
                                                                                        c.totalBulksValue
                                                                                    )}
                                                                                </strong>
                                                                            </td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                        {amountMaterialList.map(material => {
                                                                            const displayAmount =
                                                                                c.totalBulkValueBySize[material];
                                                                            return displayAmount !== 0 &&
                                                                                !_.isNil(displayAmount) ? (
                                                                                <td style={counterTableCellStyle}>
                                                                                    <strong>
                                                                                        {formatAsCurrency(
                                                                                            displayAmount
                                                                                        )}
                                                                                    </strong>
                                                                                </td>
                                                                            ) : (
                                                                                <td>&nbsp;</td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        {countersStats.length > 0 && (
                                                            <React.Fragment key={'countersStatTotals'}>
                                                                <tr style={{ 'border-top': '1px solid grey' }}>
                                                                    {countersStatTotals.totalBulksValue !== 0 &&
                                                                    !_.isNil(countersStatTotals.totalBulksValue) ? (
                                                                        <td style={counterTableCellStyle}>
                                                                            <strong>
                                                                                {formatAsCurrency(
                                                                                    countersStatTotals.totalBulksValue
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                    ) : (
                                                                        <td>&nbsp;</td>
                                                                    )}
                                                                    {amountMaterialList.map(material => {
                                                                        const displayAmount =
                                                                            countersStatTotals.totalBulkValueBySize[
                                                                                material
                                                                            ];
                                                                        return displayAmount !== 0 &&
                                                                            !_.isNil(displayAmount) ? (
                                                                            <td style={counterTableCellStyle}>
                                                                                <strong>
                                                                                    {formatAsCurrency(displayAmount)}
                                                                                </strong>
                                                                            </td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            </React.Fragment>
                                                        )}
                                                    </tbody>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={counterTableCellStyle}>Fees</td>
                                                        </tr>
                                                        {countersStats.map(c => {
                                                            return (
                                                                <React.Fragment key={c._id}>
                                                                    {groupByCountDate
                                                                        ? c.bulks.map(b => {
                                                                              const reportStatsByDate = _.get(
                                                                                  b,
                                                                                  `reportStatsByDate.${c._id}`,
                                                                                  {}
                                                                              );
                                                                              return Object.keys(reportStatsByDate).map(
                                                                                  (date, index) => {
                                                                                      const fee = _.get(
                                                                                          reportStatsByDate,
                                                                                          `${date}.fee`
                                                                                      );
                                                                                      return (
                                                                                          <tr
                                                                                              key={c._id + b._id + date}
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      c._id +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              {fee > 0 ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          counterTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {formatAsCurrency(
                                                                                                          fee
                                                                                                      )}
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              )}
                                                                                          </tr>
                                                                                      );
                                                                                  }
                                                                              );
                                                                          })
                                                                        : c.bulks.map(b => {
                                                                              const fee = _.get(
                                                                                  b,
                                                                                  `reportStats.${c._id}.fee`
                                                                              );
                                                                              return (
                                                                                  <tr
                                                                                      key={c._id + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow === c._id + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(c._id + b._id);
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      {fee > 0 ? (
                                                                                          <td
                                                                                              style={
                                                                                                  counterTableCellStyle
                                                                                              }
                                                                                          >
                                                                                              {formatAsCurrency(fee)}
                                                                                          </td>
                                                                                      ) : (
                                                                                          <td>&nbsp;</td>
                                                                                      )}
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === c._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(c._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        {c.totalBulksFee !== 0 &&
                                                                        !_.isNil(c.totalBulksFee) ? (
                                                                            <td style={counterTableCellStyle}>
                                                                                <strong>
                                                                                    {formatAsCurrency(c.totalBulksFee)}
                                                                                </strong>
                                                                            </td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        {countersStats.length > 0 && (
                                                            <React.Fragment key={'countersStatTotals'}>
                                                                <tr style={{ 'border-top': '1px solid grey' }}>
                                                                    {countersStatTotals.totalBulksFee !== 0 &&
                                                                    !_.isNil(countersStatTotals.totalBulksFee) ? (
                                                                        <td style={counterTableCellStyle}>
                                                                            <strong>
                                                                                {formatAsCurrency(
                                                                                    countersStatTotals.totalBulksFee
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                    ) : (
                                                                        <td>&nbsp;</td>
                                                                    )}
                                                                </tr>
                                                            </React.Fragment>
                                                        )}
                                                    </tbody>
                                                </div>
                                            </table>
                                        )}
                                        {groupBy === 'Bulk' && (
                                            <table
                                                data-cy="operator-clerk-report-table-group-by-bulk"
                                                style={{
                                                    display: 'flex',
                                                    overflow: 'auto',
                                                    'white-space': 'nowrap',
                                                    marginTop: theme.spacing.unit,
                                                    flexDirection: 'row',
                                                    height: 600,
                                                    borderCollapse: 'collapse'
                                                }}
                                            >
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={bulkTableCellStyle}>Label</td>
                                                            <td style={bulkTableCellStyle}>Account</td>
                                                            <td style={bulkTableCellStyle}>Counter</td>
                                                            <td style={bulkTableCellStyle}>Type</td>
                                                            {groupByCountDate && (
                                                                <td style={bulkTableCellStyle}>Count Date</td>
                                                            )}
                                                            <td style={bulkTableCellStyle}>Date Completed</td>
                                                            <td style={bulkTableCellStyle}>Processor</td>
                                                            {isEXPRegion() && <td style={bulkTableCellStyle}>Admin</td>}
                                                        </tr>
                                                        {bulkList.map(b => {
                                                            let statsIds = Object.keys(b.reportStats);
                                                            const reportStatsByCounter = _.get(
                                                                b,
                                                                `reportStatsByDate`,
                                                                {}
                                                            );

                                                            let expCounter;
                                                            let expOperator;
                                                            if (isEXPRegion()) {
                                                                const countOperator = _.find(counters, counter => {
                                                                    return counter.bulks
                                                                        .map(bulk => bulk._id)
                                                                        .includes(b._id);
                                                                });
                                                                expOperator = countOperator;
                                                                expCounter = bulkHelper.getCountedBy(b);
                                                            }

                                                            if (b.selected === false) {
                                                                return null;
                                                            }
                                                            const bOnlyOneCounter = statsIds.length === 1;
                                                            return (
                                                                <React.Fragment key={b._id}>
                                                                    {groupByCountDate
                                                                        ? Object.keys(reportStatsByCounter).map(
                                                                              counterId => {
                                                                                  // if only one counter and one session, skip and display on total line
                                                                                  if (
                                                                                      Object.keys(reportStatsByCounter)
                                                                                          .length === 1 &&
                                                                                      Object.keys(
                                                                                          reportStatsByCounter[
                                                                                              counterId
                                                                                          ]
                                                                                      ).length === 1
                                                                                  )
                                                                                      return null;
                                                                                  return Object.keys(
                                                                                      reportStatsByCounter[counterId]
                                                                                  ).map((date, index) => {
                                                                                      return (
                                                                                          <tr
                                                                                              key={
                                                                                                  counterId +
                                                                                                  b._id +
                                                                                                  date
                                                                                              }
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              <td>&nbsp;</td>
                                                                                              <td>&nbsp;</td>
                                                                                              {!isEXPRegion() && (
                                                                                                  <td
                                                                                                      style={
                                                                                                          bulkTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {_.get(
                                                                                                          b,
                                                                                                          `reportStats.${counterId}.name.first`
                                                                                                      )}{' '}
                                                                                                      {_.get(
                                                                                                          b,
                                                                                                          `reportStats.${counterId}.name.last`
                                                                                                      )}
                                                                                                      -{index + 1}
                                                                                                  </td>
                                                                                              )}
                                                                                              <td>&nbsp;</td>
                                                                                              <td
                                                                                                  style={
                                                                                                      bulkTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  {moment(date).format(
                                                                                                      'll'
                                                                                                  )}
                                                                                              </td>
                                                                                              <td>&nbsp;</td>
                                                                                              <td>&nbsp;</td>
                                                                                              {isEXPRegion() && (
                                                                                                  <>
                                                                                                      <td>&nbsp;</td>
                                                                                                      <td
                                                                                                          style={
                                                                                                              bulkTableCellStyle
                                                                                                          }
                                                                                                      >
                                                                                                          {expCounter ||
                                                                                                          _.get(
                                                                                                              b,
                                                                                                              `reportStats.${counterId}.isPreAppliedFee`,
                                                                                                              false
                                                                                                          )
                                                                                                              ? _.get(
                                                                                                                    b,
                                                                                                                    `reportStats.${counterId}.name.first`
                                                                                                                )
                                                                                                              : 'Unknown'}
                                                                                                      </td>
                                                                                                  </>
                                                                                              )}
                                                                                          </tr>
                                                                                      );
                                                                                  });
                                                                              }
                                                                          )
                                                                        : !bOnlyOneCounter &&
                                                                          statsIds.map(counterId => {
                                                                              if (
                                                                                  !_.get(
                                                                                      b,
                                                                                      `reportStats.${counterId}.selected`,
                                                                                      false
                                                                                  )
                                                                              ) {
                                                                                  return null;
                                                                              }
                                                                              return (
                                                                                  <tr
                                                                                      key={counterId + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow ===
                                                                                              counterId + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(
                                                                                              counterId + b._id
                                                                                          );
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      <td>&nbsp;</td>
                                                                                      <td>&nbsp;</td>
                                                                                      {!isEXPRegion() && (
                                                                                          <td
                                                                                              style={bulkTableCellStyle}
                                                                                          >
                                                                                              {_.get(
                                                                                                  b,
                                                                                                  `reportStats.${counterId}.name.first`
                                                                                              )}{' '}
                                                                                              {_.get(
                                                                                                  b,
                                                                                                  `reportStats.${counterId}.name.last`
                                                                                              )}
                                                                                          </td>
                                                                                      )}
                                                                                      <td>&nbsp;</td>
                                                                                      <td>&nbsp;</td>
                                                                                      <td>&nbsp;</td>
                                                                                      {isEXPRegion() && (
                                                                                          <>
                                                                                              <td>&nbsp;</td>
                                                                                              <td
                                                                                                  style={
                                                                                                      bulkTableCellStyle
                                                                                                  }
                                                                                              >
                                                                                                  {expCounter ||
                                                                                                  _.get(
                                                                                                      b,
                                                                                                      `reportStats.${counterId}.isPreAppliedFee`,
                                                                                                      false
                                                                                                  )
                                                                                                      ? _.get(
                                                                                                            b,
                                                                                                            `reportStats.${counterId}.name.first`
                                                                                                        )
                                                                                                      : 'Unknown'}
                                                                                              </td>
                                                                                          </>
                                                                                      )}
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === b._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(b._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        <td style={bulkTableCellStyle}>
                                                                            <span
                                                                                style={{
                                                                                    color: theme.palette.primary.main,
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() => handleViewBulk(b)}
                                                                            >
                                                                                {_bulk.getTruncatedLabel(b)}
                                                                            </span>
                                                                        </td>
                                                                        {_.get(b, 'isSchemeIDUser', false) ? (
                                                                            <td style={counterTableCellStyle}>CDS</td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                        {statsIds.length === 0 ? (
                                                                            <td>&nbsp;</td>
                                                                        ) : isEXPRegion() ? (
                                                                            <td style={bulkTableCellStyle}>
                                                                                {bOnlyOneCounter ? (
                                                                                    expCounter ||
                                                                                    _.get(
                                                                                        b,
                                                                                        `reportStats.${statsIds[0]}.isPreAppliedFee`,
                                                                                        false
                                                                                    ) ? (
                                                                                        _.get(
                                                                                            b,
                                                                                            `reportStats.${statsIds[0]}.name.first`
                                                                                        )
                                                                                    ) : (
                                                                                        'Unknown'
                                                                                    )
                                                                                ) : (
                                                                                    <strong>
                                                                                        {expCounter ||
                                                                                        _.get(
                                                                                            b,
                                                                                            `reportStats.${statsIds[0]}.isPreAppliedFee`,
                                                                                            false
                                                                                        )
                                                                                            ? _.get(
                                                                                                  b,
                                                                                                  `reportStats.${statsIds[0]}.name.first`
                                                                                              )
                                                                                            : 'Unknown'}
                                                                                    </strong>
                                                                                )}
                                                                            </td>
                                                                        ) : (
                                                                            <td style={bulkTableCellStyle}>
                                                                                {bOnlyOneCounter ? (
                                                                                    `${_.get(
                                                                                        b,
                                                                                        `reportStats.${statsIds[0]}.name.first`
                                                                                    )} ${_.get(
                                                                                        b,
                                                                                        `reportStats.${statsIds[0]}.name.last`
                                                                                    )}`
                                                                                ) : (
                                                                                    <strong>
                                                                                        {bOnlyOneCounter
                                                                                            ? `${_.get(
                                                                                                  b,
                                                                                                  `reportStats.${statsIds[0]}.name.first`
                                                                                              )} ${_.get(
                                                                                                  b,
                                                                                                  `reportStats.${statsIds[0]}.name.last`
                                                                                              )}`
                                                                                            : `${statsIds.length} Total`}
                                                                                    </strong>
                                                                                )}
                                                                            </td>
                                                                        )}
                                                                        <td style={bulkTableCellStyle}>
                                                                            {_.capitalize(
                                                                                bulkHelper.getBulkTypeFormatted(b)
                                                                            )}
                                                                        </td>
                                                                        {groupByCountDate && <td>&nbsp;</td>}
                                                                        <td style={bulkTableCellStyle}>
                                                                            {moment(b.dateCompleted).format('lll')}
                                                                        </td>
                                                                        <td style={bulkTableCellStyle}>
                                                                            {_.get(b, 'collector.name', '')}
                                                                        </td>
                                                                        {isEXPRegion() && (
                                                                            <td style={bulkTableCellStyle}>
                                                                                {bOnlyOneCounter ? (
                                                                                    `${_.get(
                                                                                        expOperator,
                                                                                        'name.first'
                                                                                    )} ${_.get(
                                                                                        expOperator,
                                                                                        'name.last'
                                                                                    )}`
                                                                                ) : (
                                                                                    <strong>
                                                                                        {`${_.get(
                                                                                            expOperator,
                                                                                            'name.first'
                                                                                        )} ${_.get(
                                                                                            expOperator,
                                                                                            'name.last'
                                                                                        )}`}
                                                                                    </strong>
                                                                                )}
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        <React.Fragment key={'bulkListTotals'}>
                                                            <tr style={{ 'border-top': '1px solid grey' }}>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td style={bulkTableCellStyle}>
                                                                    <strong>
                                                                        {bulkListTotals.totalBulkNumbers} Total
                                                                    </strong>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                {groupByCountDate && <td>&nbsp;</td>}
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                {isEXPRegion() && <td>&nbsp;</td>}
                                                            </tr>
                                                        </React.Fragment>
                                                    </tbody>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        Qty
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={bulkTableCellStyle}>{`Total`}</td>
                                                            {!isEXPRegion() && (
                                                                <>
                                                                    {materialList.map(material => {
                                                                        return (
                                                                            <td
                                                                                style={bulkTableCellStyle}
                                                                            >{`${material}`}</td>
                                                                        );
                                                                    })}
                                                                </>
                                                            )}
                                                        </tr>
                                                        {bulkList.map(b => {
                                                            let statsIds = Object.keys(b.reportStats);
                                                            const reportStatsByCounter = _.get(
                                                                b,
                                                                `reportStatsByDate`,
                                                                {}
                                                            );
                                                            if (b.selected === false) {
                                                                return null;
                                                            }
                                                            const bOnlyOneCounter = statsIds.length === 1;
                                                            return (
                                                                <React.Fragment key={b._id}>
                                                                    {groupByCountDate
                                                                        ? Object.keys(reportStatsByCounter).map(
                                                                              counterId => {
                                                                                  // if only one counter and one session, skip and display on total line
                                                                                  if (
                                                                                      Object.keys(reportStatsByCounter)
                                                                                          .length === 1 &&
                                                                                      Object.keys(
                                                                                          reportStatsByCounter[
                                                                                              counterId
                                                                                          ]
                                                                                      ).length === 1
                                                                                  )
                                                                                      return null;
                                                                                  return Object.keys(
                                                                                      reportStatsByCounter[counterId]
                                                                                  ).map(date => {
                                                                                      const displayAmount = _.get(
                                                                                          reportStatsByCounter,
                                                                                          `${counterId}.${date}.quantity`
                                                                                      );
                                                                                      return (
                                                                                          <tr
                                                                                              key={
                                                                                                  counterId +
                                                                                                  b._id +
                                                                                                  date
                                                                                              }
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              {displayAmount !== 0 &&
                                                                                              !_.isNil(
                                                                                                  displayAmount
                                                                                              ) ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          bulkTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {displayAmount}
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              )}

                                                                                              {!isEXPRegion() && (
                                                                                                  <>
                                                                                                      {materialList.map(
                                                                                                          material => {
                                                                                                              const displayAmount2 = _.get(
                                                                                                                  reportStatsByCounter,
                                                                                                                  `${counterId}.${date}.${material}.quantity`,
                                                                                                                  0
                                                                                                              );
                                                                                                              return displayAmount2 !==
                                                                                                                  0 &&
                                                                                                                  !_.isNil(
                                                                                                                      displayAmount2
                                                                                                                  ) ? (
                                                                                                                  <td
                                                                                                                      style={
                                                                                                                          bulkTableCellStyle
                                                                                                                      }
                                                                                                                  >
                                                                                                                      {
                                                                                                                          displayAmount2
                                                                                                                      }
                                                                                                                  </td>
                                                                                                              ) : (
                                                                                                                  <td>
                                                                                                                      &nbsp;
                                                                                                                  </td>
                                                                                                              );
                                                                                                          }
                                                                                                      )}
                                                                                                  </>
                                                                                              )}
                                                                                          </tr>
                                                                                      );
                                                                                  });
                                                                              }
                                                                          )
                                                                        : !bOnlyOneCounter &&
                                                                          statsIds.map(counterId => {
                                                                              if (
                                                                                  !_.get(
                                                                                      b,
                                                                                      `reportStats.${counterId}.selected`,
                                                                                      false
                                                                                  )
                                                                              ) {
                                                                                  return null;
                                                                              }
                                                                              const displayAmount = _.get(
                                                                                  b,
                                                                                  `reportStats.${counterId}.quantity`
                                                                              );
                                                                              return (
                                                                                  <tr
                                                                                      key={counterId + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow ===
                                                                                              counterId + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(
                                                                                              counterId + b._id
                                                                                          );
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      {displayAmount !== 0 &&
                                                                                      !_.isNil(displayAmount) ? (
                                                                                          <td
                                                                                              style={bulkTableCellStyle}
                                                                                          >
                                                                                              {displayAmount}
                                                                                          </td>
                                                                                      ) : (
                                                                                          <td>&nbsp;</td>
                                                                                      )}

                                                                                      {!isEXPRegion() && (
                                                                                          <>
                                                                                              {materialList.map(
                                                                                                  material => {
                                                                                                      const displayAmount2 = _.get(
                                                                                                          b,
                                                                                                          `reportStats.${counterId}.${material}.quantity`,
                                                                                                          0
                                                                                                      );
                                                                                                      return displayAmount2 !==
                                                                                                          0 &&
                                                                                                          !_.isNil(
                                                                                                              displayAmount2
                                                                                                          ) ? (
                                                                                                          <td
                                                                                                              style={
                                                                                                                  bulkTableCellStyle
                                                                                                              }
                                                                                                          >
                                                                                                              {
                                                                                                                  displayAmount2
                                                                                                              }
                                                                                                          </td>
                                                                                                      ) : (
                                                                                                          <td>
                                                                                                              &nbsp;
                                                                                                          </td>
                                                                                                      );
                                                                                                  }
                                                                                              )}
                                                                                          </>
                                                                                      )}
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === b._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(b._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        {b.totalBulkItemCounts !== 0 &&
                                                                        !_.isNil(b.totalBulkItemCounts) ? (
                                                                            bOnlyOneCounter ? (
                                                                                <td style={bulkTableCellStyle}>
                                                                                    {b.totalBulkItemCounts}
                                                                                </td>
                                                                            ) : (
                                                                                <td style={bulkTableCellStyle}>
                                                                                    <strong>
                                                                                        {b.totalBulkItemCounts}
                                                                                    </strong>
                                                                                </td>
                                                                            )
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                        {!isEXPRegion() && (
                                                                            <>
                                                                                {materialList.map(material => {
                                                                                    const displayAmount = _.get(
                                                                                        b,
                                                                                        `totalBulkItemCountsBySize.${material}`,
                                                                                        0
                                                                                    );
                                                                                    return displayAmount !== 0 &&
                                                                                        !_.isNil(displayAmount) ? (
                                                                                        bOnlyOneCounter ? (
                                                                                            <td
                                                                                                style={
                                                                                                    bulkTableCellStyle
                                                                                                }
                                                                                            >
                                                                                                {displayAmount}
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td
                                                                                                style={
                                                                                                    bulkTableCellStyle
                                                                                                }
                                                                                            >
                                                                                                <strong>
                                                                                                    {displayAmount}
                                                                                                </strong>
                                                                                            </td>
                                                                                        )
                                                                                    ) : (
                                                                                        <td>&nbsp;</td>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        <React.Fragment key={'bulkListTotals'}>
                                                            <tr style={{ 'border-top': '1px solid grey' }}>
                                                                {bulkListTotals.totalBulkItemCounts !== 0 &&
                                                                !_.isNil(bulkListTotals.totalBulkItemCounts) ? (
                                                                    <td style={bulkTableCellStyle}>
                                                                        <strong>
                                                                            {bulkListTotals.totalBulkItemCounts}
                                                                        </strong>
                                                                    </td>
                                                                ) : (
                                                                    <td>&nbsp;</td>
                                                                )}

                                                                {!isEXPRegion() && (
                                                                    <>
                                                                        {materialList.map(material => {
                                                                            const displayAmount = _.get(
                                                                                bulkListTotals,
                                                                                `totalBulkItemCountsBySize.${material}`,
                                                                                0
                                                                            );
                                                                            return displayAmount !== 0 &&
                                                                                !_.isNil(displayAmount) ? (
                                                                                <td style={bulkTableCellStyle}>
                                                                                    <strong>{displayAmount}</strong>
                                                                                </td>
                                                                            ) : (
                                                                                <td>&nbsp;</td>
                                                                            );
                                                                        })}
                                                                    </>
                                                                )}
                                                            </tr>
                                                        </React.Fragment>
                                                    </tbody>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        Amount
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={bulkTableCellStyle}>{`Total`}</td>
                                                            {!isEXPRegion() && (
                                                                <>
                                                                    {amountMaterialList.map(material => {
                                                                        return (
                                                                            <td
                                                                                style={bulkTableCellStyle}
                                                                            >{`${material}`}</td>
                                                                        );
                                                                    })}
                                                                </>
                                                            )}
                                                        </tr>
                                                        {bulkList.map(b => {
                                                            let statsIds = Object.keys(b.reportStats);
                                                            const reportStatsByCounter = _.get(
                                                                b,
                                                                `reportStatsByDate`,
                                                                {}
                                                            );

                                                            if (b.selected === false) {
                                                                return null;
                                                            }
                                                            const displayAmount = b.totalBulkValue;
                                                            const bOnlyOneCounter = statsIds.length === 1;
                                                            return (
                                                                <React.Fragment key={b._id}>
                                                                    {groupByCountDate
                                                                        ? Object.keys(reportStatsByCounter).map(
                                                                              counterId => {
                                                                                  // if only one counter and one session, skip and display on total line
                                                                                  if (
                                                                                      Object.keys(reportStatsByCounter)
                                                                                          .length === 1 &&
                                                                                      Object.keys(
                                                                                          reportStatsByCounter[
                                                                                              counterId
                                                                                          ]
                                                                                      ).length === 1
                                                                                  )
                                                                                      return null;
                                                                                  return Object.keys(
                                                                                      reportStatsByCounter[counterId]
                                                                                  ).map((date, index) => {
                                                                                      const displayAmount = _.get(
                                                                                          reportStatsByCounter,
                                                                                          `${counterId}.${date}.amount`
                                                                                      );
                                                                                      return (
                                                                                          <tr
                                                                                              key={
                                                                                                  counterId +
                                                                                                  b._id +
                                                                                                  date
                                                                                              }
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              {displayAmount !== 0 &&
                                                                                              !_.isNil(
                                                                                                  displayAmount
                                                                                              ) ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          bulkTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {formatAsCurrency(
                                                                                                          displayAmount
                                                                                                      )}
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              )}
                                                                                              {!isEXPRegion() && (
                                                                                                  <>
                                                                                                      {amountMaterialList.map(
                                                                                                          material => {
                                                                                                              const displayAmount2 = _.get(
                                                                                                                  reportStatsByCounter,
                                                                                                                  `${counterId}.${date}.${material}.amount`,
                                                                                                                  0
                                                                                                              );
                                                                                                              return displayAmount2 !==
                                                                                                                  0 &&
                                                                                                                  !_.isNil(
                                                                                                                      displayAmount2
                                                                                                                  ) ? (
                                                                                                                  <td
                                                                                                                      style={
                                                                                                                          bulkTableCellStyle
                                                                                                                      }
                                                                                                                  >
                                                                                                                      {formatAsCurrency(
                                                                                                                          displayAmount2
                                                                                                                      )}
                                                                                                                  </td>
                                                                                                              ) : (
                                                                                                                  <td>
                                                                                                                      &nbsp;
                                                                                                                  </td>
                                                                                                              );
                                                                                                          }
                                                                                                      )}
                                                                                                  </>
                                                                                              )}
                                                                                          </tr>
                                                                                      );
                                                                                  });
                                                                              }
                                                                          )
                                                                        : !bOnlyOneCounter &&
                                                                          statsIds.map(counterId => {
                                                                              if (
                                                                                  !_.get(
                                                                                      b,
                                                                                      `reportStats.${counterId}.selected`,
                                                                                      false
                                                                                  )
                                                                              ) {
                                                                                  return null;
                                                                              }
                                                                              const displayAmount = _.get(
                                                                                  b,
                                                                                  `reportStats.${counterId}.amount`
                                                                              );
                                                                              return (
                                                                                  <tr
                                                                                      key={counterId + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow ===
                                                                                              counterId + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(
                                                                                              counterId + b._id
                                                                                          );
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      {displayAmount !== 0 &&
                                                                                      !_.isNil(displayAmount) ? (
                                                                                          <td
                                                                                              style={bulkTableCellStyle}
                                                                                          >
                                                                                              {formatAsCurrency(
                                                                                                  displayAmount
                                                                                              )}
                                                                                          </td>
                                                                                      ) : (
                                                                                          <td>&nbsp;</td>
                                                                                      )}
                                                                                      {!isEXPRegion() && (
                                                                                          <>
                                                                                              {amountMaterialList.map(
                                                                                                  material => {
                                                                                                      const displayAmount2 = _.get(
                                                                                                          b,
                                                                                                          `reportStats.${counterId}.${material}.amount`,
                                                                                                          0
                                                                                                      );
                                                                                                      return displayAmount2 !==
                                                                                                          0 &&
                                                                                                          !_.isNil(
                                                                                                              displayAmount2
                                                                                                          ) ? (
                                                                                                          <td
                                                                                                              style={
                                                                                                                  bulkTableCellStyle
                                                                                                              }
                                                                                                          >
                                                                                                              {formatAsCurrency(
                                                                                                                  displayAmount2
                                                                                                              )}
                                                                                                          </td>
                                                                                                      ) : (
                                                                                                          <td>
                                                                                                              &nbsp;
                                                                                                          </td>
                                                                                                      );
                                                                                                  }
                                                                                              )}
                                                                                          </>
                                                                                      )}
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === b._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(b._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        {displayAmount !== 0 &&
                                                                        !_.isNil(displayAmount) ? (
                                                                            bOnlyOneCounter ? (
                                                                                <td style={bulkTableCellStyle}>
                                                                                    {formatAsCurrency(displayAmount)}
                                                                                </td>
                                                                            ) : (
                                                                                <td style={bulkTableCellStyle}>
                                                                                    <strong>
                                                                                        {formatAsCurrency(
                                                                                            displayAmount
                                                                                        )}
                                                                                    </strong>
                                                                                </td>
                                                                            )
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                        {!isEXPRegion() && (
                                                                            <>
                                                                                {amountMaterialList.map(material => {
                                                                                    const displayAmount = _.get(
                                                                                        b,
                                                                                        `totalBulkValueBySize.${material}`,
                                                                                        0
                                                                                    );
                                                                                    return displayAmount !== 0 &&
                                                                                        !_.isNil(displayAmount) ? (
                                                                                        bOnlyOneCounter ? (
                                                                                            <td
                                                                                                style={
                                                                                                    bulkTableCellStyle
                                                                                                }
                                                                                            >
                                                                                                {formatAsCurrency(
                                                                                                    displayAmount
                                                                                                )}
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td
                                                                                                style={
                                                                                                    bulkTableCellStyle
                                                                                                }
                                                                                            >
                                                                                                <strong>
                                                                                                    {formatAsCurrency(
                                                                                                        displayAmount
                                                                                                    )}
                                                                                                </strong>
                                                                                            </td>
                                                                                        )
                                                                                    ) : (
                                                                                        <td>&nbsp;</td>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        <React.Fragment key={'bulkListTotals'}>
                                                            <tr style={{ 'border-top': '1px solid grey' }}>
                                                                {bulkListTotals.totalBulkValue !== 0 &&
                                                                !_.isNil(bulkListTotals.totalBulkValue) ? (
                                                                    <td style={bulkTableCellStyle}>
                                                                        <strong>
                                                                            {formatAsCurrency(
                                                                                bulkListTotals.totalBulkValue
                                                                            )}
                                                                        </strong>
                                                                    </td>
                                                                ) : (
                                                                    <td>&nbsp;</td>
                                                                )}
                                                                {!isEXPRegion() && (
                                                                    <>
                                                                        {amountMaterialList.map(material => {
                                                                            const displayAmount = _.get(
                                                                                bulkListTotals,
                                                                                `totalBulkValueBySize.${material}`,
                                                                                0
                                                                            );
                                                                            return displayAmount !== 0 &&
                                                                                !_.isNil(displayAmount) ? (
                                                                                <td style={bulkTableCellStyle}>
                                                                                    <strong>
                                                                                        {formatAsCurrency(
                                                                                            displayAmount
                                                                                        )}
                                                                                    </strong>
                                                                                </td>
                                                                            ) : (
                                                                                <td>&nbsp;</td>
                                                                            );
                                                                        })}
                                                                    </>
                                                                )}
                                                            </tr>
                                                        </React.Fragment>
                                                    </tbody>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                    <tbody
                                                        style={{
                                                            fontSize: theme.typography.caption.fontSize,
                                                            borderCollapse: 'collapse'
                                                        }}
                                                    >
                                                        <tr>
                                                            <td style={bulkTableCellStyle}>Fees</td>
                                                        </tr>
                                                        {bulkList.map(b => {
                                                            let statsIds = Object.keys(b.reportStats);
                                                            const reportStatsByCounter = _.get(
                                                                b,
                                                                `reportStatsByDate`,
                                                                {}
                                                            );

                                                            if (b.selected === false) {
                                                                return null;
                                                            }
                                                            const bOnlyOneCounter = statsIds.length === 1;
                                                            const firstFee = _.get(
                                                                b,
                                                                `reportStats.${statsIds[0]}.fee`,
                                                                0
                                                            );
                                                            const firstSelected = !_.get(
                                                                b,
                                                                `reportStats.${statsIds[0]}.selected`,
                                                                false
                                                            );
                                                            return (
                                                                <React.Fragment key={b._id}>
                                                                    {groupByCountDate
                                                                        ? Object.keys(reportStatsByCounter).map(
                                                                              counterId => {
                                                                                  // if only one counter and one session, skip and display on total line
                                                                                  if (
                                                                                      Object.keys(reportStatsByCounter)
                                                                                          .length === 1 &&
                                                                                      Object.keys(
                                                                                          reportStatsByCounter[
                                                                                              counterId
                                                                                          ]
                                                                                      ).length === 1
                                                                                  )
                                                                                      return null;
                                                                                  return Object.keys(
                                                                                      reportStatsByCounter[counterId]
                                                                                  ).map((date, index) => {
                                                                                      const fee = _.get(
                                                                                          reportStatsByCounter,
                                                                                          `${counterId}.${date}.fee`,
                                                                                          0
                                                                                      );
                                                                                      return (
                                                                                          <tr
                                                                                              key={
                                                                                                  counterId +
                                                                                                  b._id +
                                                                                                  date
                                                                                              }
                                                                                              style={{
                                                                                                  backgroundColor:
                                                                                                      hoverRow ===
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                          ? colors
                                                                                                                .grey[200]
                                                                                                          : undefined
                                                                                              }}
                                                                                              onMouseOver={() => {
                                                                                                  setHoverRow(
                                                                                                      counterId +
                                                                                                          b._id +
                                                                                                          date
                                                                                                  );
                                                                                              }}
                                                                                              onMouseLeave={() => {
                                                                                                  setHoverRow('');
                                                                                              }}
                                                                                          >
                                                                                              {fee > 0 &&
                                                                                              !_.isNil(fee) ? (
                                                                                                  <td
                                                                                                      style={
                                                                                                          bulkTableCellStyle
                                                                                                      }
                                                                                                  >
                                                                                                      {formatAsCurrency(
                                                                                                          fee
                                                                                                      )}
                                                                                                  </td>
                                                                                              ) : (
                                                                                                  <td>&nbsp;</td>
                                                                                              )}
                                                                                          </tr>
                                                                                      );
                                                                                  });
                                                                              }
                                                                          )
                                                                        : !bOnlyOneCounter &&
                                                                          statsIds.map(counterId => {
                                                                              const fee = _.get(
                                                                                  b,
                                                                                  `reportStats.${counterId}.fee`,
                                                                                  0
                                                                              );
                                                                              if (
                                                                                  !_.get(
                                                                                      b,
                                                                                      `reportStats.${counterId}.selected`,
                                                                                      false
                                                                                  )
                                                                              ) {
                                                                                  return null;
                                                                              }
                                                                              return (
                                                                                  <tr
                                                                                      key={counterId + b._id}
                                                                                      style={{
                                                                                          backgroundColor:
                                                                                              hoverRow ===
                                                                                              counterId + b._id
                                                                                                  ? colors.grey[200]
                                                                                                  : undefined
                                                                                      }}
                                                                                      onMouseOver={() => {
                                                                                          setHoverRow(
                                                                                              counterId + b._id
                                                                                          );
                                                                                      }}
                                                                                      onMouseLeave={() => {
                                                                                          setHoverRow('');
                                                                                      }}
                                                                                  >
                                                                                      {fee > 0 && !_.isNil(fee) ? (
                                                                                          <td
                                                                                              style={bulkTableCellStyle}
                                                                                          >
                                                                                              {formatAsCurrency(fee)}
                                                                                          </td>
                                                                                      ) : (
                                                                                          <td>&nbsp;</td>
                                                                                      )}
                                                                                  </tr>
                                                                              );
                                                                          })}
                                                                    <tr
                                                                        style={{
                                                                            backgroundColor:
                                                                                hoverRow === b._id
                                                                                    ? colors.grey[200]
                                                                                    : undefined
                                                                        }}
                                                                        onMouseOver={() => {
                                                                            setHoverRow(b._id);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setHoverRow('');
                                                                        }}
                                                                    >
                                                                        {bOnlyOneCounter &&
                                                                        firstSelected &&
                                                                        firstFee > 0 &&
                                                                        !_.isNil(firstFee) ? (
                                                                            <td style={bulkTableCellStyle}>
                                                                                {formatAsCurrency(firstFee)}
                                                                            </td>
                                                                        ) : !_.isNil(b.totalBulkFee) &&
                                                                          b.totalBulkFee > 0 ? (
                                                                            <td style={counterTableCellStyle}>
                                                                                {bOnlyOneCounter ? (
                                                                                    formatAsCurrency(b.totalBulkFee)
                                                                                ) : (
                                                                                    <strong>
                                                                                        {formatAsCurrency(
                                                                                            b.totalBulkFee
                                                                                        )}
                                                                                    </strong>
                                                                                )}
                                                                            </td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        <React.Fragment key={'bulkListTotals'}>
                                                            <tr style={{ 'border-top': '1px solid grey' }}>
                                                                {!_.isNil(feeInAllBulks) && feeInAllBulks > 0 ? (
                                                                    <td style={counterTableCellStyle}>
                                                                        <strong>
                                                                            {formatAsCurrency(feeInAllBulks)}
                                                                        </strong>
                                                                    </td>
                                                                ) : (
                                                                    <td>&nbsp;</td>
                                                                )}
                                                                <td style={bulkTableCellStyle}>{''}</td>
                                                                <td style={bulkTableCellStyle}>{''}</td>
                                                                <td style={bulkTableCellStyle}>{''}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    </tbody>
                                                </div>
                                            </table>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <Typography variant="h6">Employees</Typography>
                                    <div
                                        style={{
                                            border: `2px solid ${theme.palette.grey[200]}`,
                                            borderRadius: 4,
                                            padding: theme.spacing.unit,
                                            height: 300,
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <List dense>
                                            {counters.map(receiver => (
                                                <ListItem
                                                    key={receiver._id}
                                                    role={undefined}
                                                    button
                                                    onClick={() => handleToggleCountersSelected(receiver._id)}
                                                >
                                                    <Checkbox
                                                        style={{ padding: 0 }}
                                                        checked={receiver.selected}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                    <ListItemText
                                                        primary={`${receiver.name.first} ${receiver.name.last}`}
                                                        secondary={`Containers: [${receiver.containersPerDay}/day] [${receiver.containersPerSort}/sort]`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                    <Typography variant="h6">Summary</Typography>
                                    <div
                                        style={{
                                            border: `2px solid ${theme.palette.grey[200]}`,
                                            borderRadius: 4,
                                            padding: theme.spacing.unit,
                                            height: 268,
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <table
                                            data-cy="operator-clerk-report-table-group-by-bulk"
                                            style={{
                                                display: 'block',
                                                'overflow-x': 'auto',
                                                'white-space': 'nowrap',
                                                marginTop: theme.spacing.unit
                                            }}
                                        >
                                            <tbody style={{ fontSize: theme.typography.caption.fontSize }}>
                                                <tr>
                                                    <td style={{ marginBottom: 5, paddingRight: 10 }}>
                                                        <Typography>SKU</Typography>
                                                    </td>
                                                    <td style={{ paddingRight: 10 }}>
                                                        <Typography>Name</Typography>
                                                    </td>
                                                    <td style={{ paddingRight: 10 }}>
                                                        <Typography>Count</Typography>
                                                    </td>
                                                    <td style={{ paddingRight: 10 }}>
                                                        <Typography>Amount</Typography>
                                                    </td>
                                                </tr>
                                                {materialList.map(material => {
                                                    let amount = _.get(
                                                        countersStatTotals,
                                                        `totalBulkValueBySize.${material}`,
                                                        null
                                                    );
                                                    let count = _.get(
                                                        countersStatTotals,
                                                        `totalBulkItemCountsBySize.${material}`,
                                                        null
                                                    );
                                                    return (
                                                        <tr>
                                                            <td style={{ marginBottom: 5, paddingRight: 10 }}>
                                                                <Typography>{materialToSkuMap[material]}</Typography>
                                                            </td>
                                                            <td style={{ paddingRight: 10 }}>
                                                                <Typography> {material}</Typography>
                                                            </td>
                                                            <td style={{ paddingRight: 10 }}>
                                                                <Typography> {count}</Typography>
                                                            </td>
                                                            <td style={{ paddingRight: 10 }}>
                                                                <Typography>{formatAsCurrency(amount)}</Typography>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default withTheme()(BulkStats);
