import React, { useEffect, useState, useReducer, useRef, useMemo, useCallback, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';

import { SplashScreen } from '@capacitor/splash-screen';
import { PushNotifications } from '@capacitor/push-notifications';
import { App as CapacitorAppPlugin } from '@capacitor/app';

import _ from 'lodash';

import VERSION_FRONT from 'VERSION';

import Main from './Main/Main';
import Login from './Login/Login';
import DepotLogin from './Login/DepotLogin';
import Registration from './Registration/Registration';
import Terms from './Legal/Terms/Terms';
import Privacy from './Legal/Privacy/Privacy';
import Rates from './Legal/Rates/Rates';
import Verification from './Verification/Verification';
import ConfirmPickup from './ConfirmPickup/ConfirmPickup';
import PasswordReset from './PasswordReset/PasswordReset';
import ExternalOTCPortal from './External/ExternalOTCPortal';

import ReferralRedirect from 'components/ReferralRedirect/ReferralRedirect';
import HttpErrorDialog from 'components/HttpErrorDialog/HttpErrorDialog';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';
import ConfirmDialog from 'components/Dialogs/Confirm';
import UpdateRequiredDialog from 'components/BlockingDialogs/UpdateRequiredDialog';
import ServerMaintenanceDialog from 'components/BlockingDialogs/ServerMaintenanceDialog';

import DebugOverlay from 'components/Debug/DebugOverlay';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { HttpProvider } from 'utils/contexts/HttpContext';
import { PermissionsProvider } from 'utils/contexts/PermissionsContext';
import { ConfirmDialogProvider } from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { DebugProvider } from 'components/CustomDebugDialog/DebugContext';
import { SnackbarProvider } from 'components/CustomSnackbar/SnackbarContext';
import { GoogleProvider } from 'utils/contexts/GoogleContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { LocalizationProvider } from 'utils/contexts/LocalizationContext';

import { useHistory } from 'react-router-dom';

import {
    lightTheme as defaultLightTheme,
    darkTheme as defaultDarkTheme,
    adminTheme as defaultAdminTheme,
    markerPinTheme
} from 'theme';

import {
    isProductionEnv,
    deviceHelper,
    changeRequiresRefresh,
    breakingChangeRequiresUpdate,
    breakingChangeAwaitingServer,
    patchCanUpdate,
    isEXPRegion
} from 'utils/misc';
// custom hooks
import useCustomDebugDialog from 'components/CustomDebugDialog/hooks/useCustomDebugDialog';
import useCustomSnackbar from 'components/CustomSnackbar/hooks/useCustomSnackbar';
import useConfirmDialog from 'components/Dialogs/Confirm/useConfirmDialog';
import useHttp from './useHttp';
import usePermissions from 'utils/hooks/usePermissions';
import useFetchProbe from 'utils/hooks/useFetchProbe';

import { AVAILABLE_LANGS } from '../constants';
import GenericDialog from 'components/Dialogs/GenericDialog';

import { loc } from '../localizations/localizationHandler';
import Authorization from './Authorization/Authorization';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import DownloadApp from './DownloadApp/DownloadApp';

const DEBUG = process.env.REACT_APP_DEBUG;
const DEFAULT_LANG = process.env.REACT_APP_REGION_LANGUAGE;

function dispatchAuthReducer(state, action) {
    switch (action.type) {
        case 'SET_AUTH_TRUE':
            state = _.omit(action, 'type');
            state.isAuthenticated = true;
            return state;
        case 'SET_AUTH_FALSE':
            return {
                isAuthenticated: false,
                accountType: undefined,
                name: undefined,
                _id: undefined,
                collector_id: undefined,
                home: undefined,
                accountPermissions: undefined
            };
        default:
            return state;
    }
}

const App = props => {
    const { google, handleLang } = props;

    const [onLoginMessage, setOnLoginMessage] = useState(null);
    const [onLoginMessageVariant, setOnLoginMessageVariant] = useState('success');

    const [lightTheme, setLightTheme] = useState(defaultLightTheme);
    const [darkTheme, setDarkTheme] = useState(defaultDarkTheme);
    const [adminTheme, setAdminTheme] = useState(defaultAdminTheme);
    const [brandLogoOverride, setBrandLogoOverride] = useState({});
    const [theme, setTheme] = useState(lightTheme);
    const [placesService, setPlacesService] = useState(null);

    const [loadingServerInfo, setLoadingServerInfo] = useState(true);
    // const [serverVersion, setServerVersion] = useState(null);

    const [refreshRequired, setRefreshRequired] = useState(false);
    const [updateRequired, setUpdateRequired] = useState(false);
    const [serverMaintenance, setServerMaintenance] = useState(false);

    const [showRefreshDialog, setShowRefreshDialog] = useState(false);

    const [showThirdPartyLogins, setShowThirdPartyLogins] = useState(true);
    const [registrationConfig, setRegistrationConfig] = useState({});

    const [debugHistory, setDebugHistory] = useState([]);

    const [promosEnabled, setPromosEnabled] = useState(true);
    const [pickupsEnabled, setPickupsEnabled] = useState(true);
    const [charityEnabled, setCharityEnabled] = useState(true);
    const [scanQREnabled, setScanQREnabled] = useState(false);
    const [showQREnabled, setShowQREnabled] = useState(false);
    const [collectionProgramEnabled, setCollectionProgramEnabled] = useState(false);

    const [helpCenterFunctions, setHelpCenterFunctions] = useState({
        showHowItWorks: true,
        showFaq: true,
        showContactUs: true
    });
    const [showZohoHelpDesk, setShowZohoHelpDesk] = useState(false);

    const [idleLogoutTimeoutConfig, setIdleLogoutTimeoutConfig] = useState({});
    const [idleLogoutDialogOpen, setIdleLogoutDialogOpen] = useState(false);

    const { lang } = useContext(LocalizationContext);

    const history = useHistory();

    // auth dispatcher
    const [auth, dispatch] = useReducer(dispatchAuthReducer, {
        isAuthenticated: undefined, // never set this to false initially, will cause page refresh errors
        accountType: undefined,
        name: undefined,
        _id: undefined,
        collector_id: undefined,
        home: undefined,
        multipleAccountAccessList: undefined
    });

    // TODO turn snackbar into a context to prevent prop drilling
    const { snackbarOpen, snackbarVariant, snackbarMessage, snackbarDuration, handleSnackbar } = useCustomSnackbar();

    const {
        http,
        httpErrorDialogOpen,
        httpErrorType,
        handleCloseHttpErrorDialog,
        oversizedFileSize,
        bytesSent,
        bytesReceived
    } = useHttp({
        dispatch,
        DEBUG,
        debugHistory,
        setDebugHistory
    });

    const {
        open: debugDialogOpen,
        json: debugDialogJSON,
        handleCloseDebugDialog,
        handleDebugDialog
    } = useCustomDebugDialog();

    const {
        callbackFunc,
        message: confirmMessage,
        warning: confirmWarning,
        open: confirmDialogOpen,
        enableConfirm,
        confirmString,
        confirmStringComparisonValue,
        handleCloseConfirmDialog,
        handleConfirmAction,
        handleChangeString,
        confirmButtonText,
        cancelButtonText
    } = useConfirmDialog();

    const { customerPermissions } = usePermissions({ http, auth });

    const { fetchProbe } = useFetchProbe({ dispatch, handleSnackbar, handleLang });

    const clearHistory = () => {
        setDebugHistory([]);
    };

    const getEventSources = async () => {
        let res = await http.getJSON('/logs/eventSources');

        if (res.ok) {
            const eventSources = _.get(res, 'data.eventSources', []);

            const logEvent = {
                type: 'event-sources',
                size: 0,
                text: JSON.stringify(eventSources)
            };

            setDebugHistory(debugHistory => [...debugHistory, logEvent]);
        }
    };

    const handleTheme = theme => {
        let lightThemeCopy = _.cloneDeep(lightTheme);
        let darkThemeCopy = _.cloneDeep(darkTheme);
        let adminThemeCopy = _.cloneDeep(adminTheme);
        const keys = [
            '50',
            '100',
            '200',
            '300',
            '400',
            '500',
            '600',
            '700',
            '800',
            '900',
            'A100',
            'A200',
            'A400',
            'A700',
            'main',
            'light',
            'dark'
        ];

        let charityPin = _.get(theme, 'charityPin', markerPinTheme.charityPin);
        let schemeUserPin = _.get(theme, 'schemeUserPin', markerPinTheme.schemeUserPin);
        let pinLabelStyle = _.get(theme, 'pinLabelStyle', markerPinTheme.pinLabelStyle);
        let technologyLogo = _.get(theme, 'technologyLogo', markerPinTheme.technologyLogo);
        let noTechnologyLogo = _.get(theme, 'noTechnologyLogo', markerPinTheme.noTechnologyLogo);

        const markerThemeObj = {
            charityPin,
            schemeUserPin,
            pinLabelStyle,
            noTechnologyLogo,
            technologyLogo
        };

        for (let key of keys) {
            // set light theme values
            lightThemeCopy.palette.primary[key] = _.get(theme, 'light.primary', lightThemeCopy.palette.primary[key]);
            lightThemeCopy.palette.secondary[key] = _.get(
                theme,
                'light.secondary',
                lightThemeCopy.palette.secondary[key]
            );

            // set dark theme values
            darkThemeCopy.palette.primary[key] = _.get(theme, 'dark.primary', darkThemeCopy.palette.primary[key]);
            darkThemeCopy.palette.secondary[key] = _.get(theme, 'dark.secondary', darkThemeCopy.palette.secondary[key]);

            // set admin theme values
            adminThemeCopy.palette.primary[key] = _.get(theme, 'admin.primary', adminThemeCopy.palette.primary[key]);
            adminThemeCopy.palette.secondary[key] = _.get(
                theme,
                'admin.secondary',
                adminThemeCopy.palette.secondary[key]
            );
        }

        lightThemeCopy.palette.linkColor = lightThemeCopy.palette.primary[500];
        darkThemeCopy.palette.linkColor = darkThemeCopy.palette.primary[300];
        adminThemeCopy.palette.linkColor = adminThemeCopy.palette.primary;

        lightThemeCopy.markerTheme = markerThemeObj;
        darkThemeCopy.markerTheme = markerThemeObj;
        adminThemeCopy.markerTheme = markerThemeObj;

        setLightTheme(lightThemeCopy);
        setDarkTheme(darkThemeCopy);
        setAdminTheme(adminThemeCopy);

        let themeType = localStorage.getItem('theme');
        if (themeType === 'dark') {
            document.body.style.backgroundColor = darkTheme.palette.background.default;
            setTheme(darkTheme);
        } else {
            document.body.style.backgroundColor = lightTheme.palette.background.default;
            setTheme(lightTheme);
        }
    };

    const handleBrandLogo = brandLogo => {
        setBrandLogoOverride(brandLogo);
    };

    const handleServerConfig = serverConfig => {
        const showThirdPartyLogins = _.get(serverConfig, 'showThirdPartyLogins', true);
        const resServerVersion = _.get(serverConfig, 'serverVersion', 'legacy');
        const forcePatchUpdate = _.get(serverConfig, 'forcePatchUpdate', false);
        const disableRefreshCheck = _.get(serverConfig, 'disableRefreshCheck', false);
        const disableUpdateCheck = _.get(serverConfig, 'disableUpdateCheck', false);
        const disableMaintenanceCheck = _.get(serverConfig, 'disableMaintenanceCheck', false);
        const timeoutConfig = _.get(serverConfig, 'idleLogoutTimeoutConfig', {});
        const registrationConfig = _.get(serverConfig, 'registrationConfig', {});

        // Refresh
        const patchRequiresRefresh = changeRequiresRefresh(resServerVersion, VERSION_FRONT);

        // Forced Update
        const minorRequiresUpdate = breakingChangeRequiresUpdate(resServerVersion, VERSION_FRONT);
        const patchRequiresUpdate = forcePatchUpdate && patchCanUpdate(resServerVersion, VERSION_FRONT);

        // Server maintenance
        const minorRequiresMaintenance = breakingChangeAwaitingServer(resServerVersion, VERSION_FRONT);

        const refreshReq = !disableRefreshCheck && patchRequiresRefresh;
        const updateReq = !disableUpdateCheck && (patchRequiresUpdate || minorRequiresUpdate);
        const serverMaintenanceReq = !disableMaintenanceCheck && minorRequiresMaintenance;

        // setServerVersion(resServerVersion);

        setIdleLogoutTimeoutConfig(timeoutConfig);
        setRegistrationConfig(registrationConfig);

        setShowThirdPartyLogins(showThirdPartyLogins);

        setRefreshRequired(refreshReq);
        setUpdateRequired(updateReq);
        setShowRefreshDialog(refreshReq || updateReq);
        setServerMaintenance(serverMaintenanceReq);
    };

    const handleEnabledFunctions = enabledFunctions => {
        setPickupsEnabled(_.get(enabledFunctions, 'pickupsEnabled', true));
        setPromosEnabled(_.get(enabledFunctions, 'promosEnabled', true));
        setCharityEnabled(_.get(enabledFunctions, 'charityEnabled', true));
        setScanQREnabled(_.get(enabledFunctions, 'scanQREnabled', false));
        setShowQREnabled(_.get(enabledFunctions, 'showQREnabled', false));
        setCollectionProgramEnabled(_.get(enabledFunctions, 'collectionProgramEnabled', false));
    };

    const handleHelpConfig = helpConfig => {
        setHelpCenterFunctions(
            _.get(helpConfig, 'helpCenterFunctions', {
                showHowItWorks: true,
                showFaq: true,
                showContactUs: true
            })
        );
        setShowZohoHelpDesk(_.get(helpConfig, 'showZohoHelpDesk', false));
    };

    const loadAppConfig = async () => {
        setLoadingServerInfo(true);

        const res = await http.getJSON('/app/config/default');

        if (res.ok && !_.isEmpty(res.data)) {
            const theme = _.get(res, 'data.theme', {});
            handleTheme(theme);

            const brandLogo = _.get(res, 'data.brandLogo');
            handleBrandLogo(brandLogo);

            const serverConfig = _.get(res, 'data.serverConfig', {});
            handleServerConfig(serverConfig);

            const enabledFunctions = _.get(res, 'data.enabledFunctions', {});
            handleEnabledFunctions(enabledFunctions);

            const helpConfig = _.get(res, 'data.helpConfig', {});
            handleHelpConfig(helpConfig);
        }

        setLoadingServerInfo(false);
    };

    useEffect(() => {
        let themeType = localStorage.getItem('theme');
        if (themeType === 'dark') {
            document.body.style.backgroundColor = darkTheme.palette.background.default;
            setTheme(darkTheme);
        } else {
            document.body.style.backgroundColor = lightTheme.palette.background.default;
            setTheme(lightTheme);
        }
    }, [lightTheme, darkTheme, adminTheme]);

    useEffect(() => {
        document.documentElement.style.setProperty('--custom-safe-area-top', '0px');
        setCustomSafeAreaTop();
    }, []);

    useEffect(() => {
        //remove all push notification badges from the app on ios when it comes into focus
        if (deviceHelper.iOSCordova()) {
            CapacitorAppPlugin.addListener('appStateChange', state => {
                if (state.isActive) {
                    PushNotifications.removeAllDeliveredNotifications();
                }
            });
        }
    }, []);

    useEffect(() => {
        fetchProbe();
        // Set theme from localStorage:

        if (isProductionEnv && !_.isNil(window.fbq)) {
            window.fbq('track', 'Lead');
        }

        loadAppConfig();

        //try to avoid calling new google.maps.Map() alot as it counts towards our quota for map loads
        setPlacesService(new google.maps.places.PlacesService(new google.maps.Map(document.createElement('div'))));
    }, []); // leave this array empty so it runs only when the component is mounted

    useEffect(() => {
        if (!DEBUG) return;
        console.log('%cTheme: ', 'color: purple; font-weight: bold;', theme);
    }, [theme]);

    useEffect(() => {
        if (!DEBUG) return;
        console.log('%cAuth: ', 'color: purple; font-weight: bold;', auth);
    }, [auth]);

    useEffect(() => {
        if (!DEBUG) return;
        console.log('%cWindow: ', 'color: orange; font-weight: bold;', window);
    }, [window]);

    const handleNightModeToggle = () => {
        if (theme === lightTheme) {
            document.body.style.backgroundColor = darkTheme.palette.background.default;
            localStorage.setItem('theme', 'dark');
            setTheme(darkTheme);
        } else {
            document.body.style.backgroundColor = lightTheme.palette.background.default;
            localStorage.setItem('theme', 'light');
            setTheme(lightTheme);
        }
    };

    const handleAdminTheme = state => {
        if (state) {
            document.body.style.backgroundColor = adminTheme.palette.background.default;
            setTheme(adminTheme);
        } else {
            let themeType = localStorage.getItem('theme'); //get previously used theme
            if (themeType === 'dark') {
                document.body.style.backgroundColor = darkTheme.palette.background.default;
                setTheme(darkTheme);
            } else {
                document.body.style.backgroundColor = lightTheme.palette.background.default;
                setTheme(lightTheme);
            }
        }
    };

    useEffect(() => {
        try {
            SplashScreen.hide();
        } catch (err) {}
    }, []);

    useEffect(() => {
        try {
            if (deviceHelper.isNativeApp()) {
                PushNotifications.addListener('pushNotificationActionPerformed', actionPerformed => {
                    const clickActionLink = _.get(actionPerformed, 'notification.data.clickActionLink');
                    try {
                        if (!_.isEmpty(clickActionLink)) {
                            if (_.startsWith(clickActionLink, process.env.REACT_APP_ORIGIN_URL)) {
                                const urlEnd = clickActionLink.substring(process.env.REACT_APP_ORIGIN_URL.length);
                                history.push(urlEnd);
                            } else {
                                window.location.replace(clickActionLink);
                            }
                        }
                    } catch (err) {}
                });
            }
        } catch (err) {}
    }, []);

    const googleContextValue = useMemo(() => ({ google, placesService }), [google, placesService]);

    if (_.isNil(auth.isAuthenticated) || loadingServerInfo) {
        // Users will get stuck here if no internet connection
        return <LoadingScreen color={theme.palette.primary.main} />;
    }

    if (auth.isAuthenticated && updateRequired) {
        return <UpdateRequiredDialog open={true} disabling={true} />;
    }

    if (auth.isAuthenticated && serverMaintenance) {
        return <ServerMaintenanceDialog open={true} />;
    }

    if (auth.isAuthenticated) {
        return (
            <MuiThemeProvider theme={theme}>
                <HttpProvider value={http}>
                    <PermissionsProvider value={{ customerPermissions }}>
                        <DebugProvider value={handleDebugDialog}>
                            <ConfirmDialogProvider value={handleConfirmAction}>
                                <SnackbarProvider value={handleSnackbar}>
                                    <GoogleProvider value={googleContextValue}>
                                        <Main
                                            onLoginMessage={onLoginMessage}
                                            onLoginMessageVariant={onLoginMessageVariant}
                                            google={google}
                                            theme={theme}
                                            http={http}
                                            auth={auth}
                                            dispatch={dispatch}
                                            toggleNightMode={handleNightModeToggle}
                                            onSnackbar={handleSnackbar}
                                            onAdminTheme={handleAdminTheme}
                                            refreshRequired={refreshRequired}
                                            showThirdPartyLogins={showThirdPartyLogins}
                                            brandLogoOverride={brandLogoOverride}
                                            promosEnabled={promosEnabled}
                                            pickupsEnabled={pickupsEnabled}
                                            charityEnabled={charityEnabled}
                                            collectionProgramEnabled={collectionProgramEnabled}
                                            idleLogoutTimeoutConfig={idleLogoutTimeoutConfig}
                                            onShowIdleLogoutDialog={() => setIdleLogoutDialogOpen(true)}
                                            scanQREnabled={scanQREnabled}
                                            showQREnabled={showQREnabled}
                                            registrationConfig={registrationConfig}
                                            helpCenterFunctions={helpCenterFunctions}
                                            darkTheme={darkTheme}
                                            lightTheme={lightTheme}
                                            setTheme={setTheme}
                                        />
                                    </GoogleProvider>
                                </SnackbarProvider>
                            </ConfirmDialogProvider>
                        </DebugProvider>
                    </PermissionsProvider>
                </HttpProvider>

                <ConfirmDialog
                    open={confirmDialogOpen}
                    message={confirmMessage}
                    warning={confirmWarning}
                    confirmString={confirmString}
                    confirmStringComparisonValue={confirmStringComparisonValue}
                    enableConfirm={enableConfirm}
                    callbackFunc={callbackFunc}
                    onCloseConfirmDialog={handleCloseConfirmDialog}
                    onChangeString={handleChangeString}
                    confirmButtonText={confirmButtonText}
                    cancelButtonText={cancelButtonText}
                />

                <HttpErrorDialog
                    open={httpErrorDialogOpen}
                    errorType={httpErrorType}
                    onClose={handleCloseHttpErrorDialog}
                    oversizedFileSize={oversizedFileSize}
                />

                <CustomSnackbar
                    open={snackbarOpen}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    duration={snackbarDuration}
                    onClose={() => handleSnackbar()}
                />
                <CustomDebugDialog open={debugDialogOpen} json={debugDialogJSON} onClose={handleCloseDebugDialog} />
                <HttpProvider value={http}>
                    <DebugOverlay
                        bytesReceived={bytesReceived}
                        bytesSent={bytesSent}
                        debugHistory={debugHistory}
                        getEventSources={getEventSources}
                        clearHistory={clearHistory}
                    />
                </HttpProvider>
                <ZohoWidget show={showZohoHelpDesk} auth={auth} />
            </MuiThemeProvider>
        );
    }

    if (!auth.isAuthenticated && serverMaintenance) {
        // Do nothing for now
    }

    return (
        <MuiThemeProvider theme={theme}>
            <HttpProvider value={http}>
                <DebugProvider value={handleDebugDialog}>
                    <ConfirmDialogProvider value={handleConfirmAction}>
                        <SnackbarProvider value={handleSnackbar}>
                            <GoogleProvider value={googleContextValue}>
                                <Switch history={history}>
                                    <Route
                                        exact
                                        path="/login"
                                        render={props => (
                                            <Login
                                                {...props}
                                                http={http}
                                                theme={theme}
                                                auth={auth}
                                                dispatch={dispatch}
                                                setLang={handleLang}
                                                onSnackbar={handleSnackbar}
                                                setOnLoginMessage={setOnLoginMessage}
                                                setOnLoginMessageVariant={setOnLoginMessageVariant}
                                                useFallbackData={updateRequired}
                                                showThirdPartyLogins={showThirdPartyLogins}
                                                brandLogoOverride={brandLogoOverride}
                                                helpCenterFunctions={helpCenterFunctions}
                                                collectionProgramEnabled={collectionProgramEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/:collectorID/login"
                                        render={props => (
                                            <DepotLogin {...props} theme={theme} dispatch={dispatch} auth={auth} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/register"
                                        render={props => (
                                            <Registration
                                                {...props}
                                                http={http}
                                                google={google}
                                                theme={theme}
                                                dispatch={dispatch}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                                showThirdPartyLogins={showThirdPartyLogins}
                                                brandLogoOverride={brandLogoOverride}
                                                promosEnabled={promosEnabled}
                                                charityEnabled={charityEnabled}
                                                registrationConfig={registrationConfig}
                                                helpCenterFunctions={helpCenterFunctions}
                                                auth={auth}
                                                collectionProgramEnabled={collectionProgramEnabled}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/terms"
                                        render={props => (
                                            <Terms
                                                {...props}
                                                theme={theme}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/privacy"
                                        render={props => (
                                            <Privacy
                                                {...props}
                                                theme={theme}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/rates"
                                        render={props => (
                                            <Rates
                                                {...props}
                                                theme={theme}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/verify/:email/:token"
                                        render={props => (
                                            <Verification
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/changeEmail/:_id/:email/:token"
                                        render={props => (
                                            <ChangeEmail
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/authorize/:_id/:email/:token"
                                        render={props => (
                                            <Authorization
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/confirm/:pickupID"
                                        render={props => (
                                            <ConfirmPickup
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/reset/:id/:token"
                                        render={props => (
                                            <PasswordReset
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/external"
                                        render={props => <ExternalOTCPortal {...props} theme={theme} />}
                                    />
                                    {scanQREnabled && (
                                        <Route
                                            exact
                                            path="/qs/:token"
                                            render={props => (
                                                <DownloadApp
                                                    {...props}
                                                    brandLogoOverride={brandLogoOverride}
                                                    auth={auth}
                                                />
                                            )}
                                        />
                                    )}
                                    <Route
                                        exact
                                        path="/:url"
                                        render={props => (
                                            <ReferralRedirect
                                                {...props}
                                                http={http}
                                                lang={lang}
                                                theme={theme}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/:url/:subdivision"
                                        render={props => (
                                            <ReferralRedirect
                                                {...props}
                                                http={http}
                                                lang={lang}
                                                theme={theme}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Redirect to={isEXPRegion() ? '/login' : '/register'} />
                                </Switch>
                            </GoogleProvider>
                        </SnackbarProvider>
                    </ConfirmDialogProvider>
                </DebugProvider>
            </HttpProvider>

            <HttpErrorDialog
                open={httpErrorDialogOpen}
                errorType={httpErrorType}
                onClose={handleCloseHttpErrorDialog}
                oversizedFileSize={oversizedFileSize}
            />

            <GenericDialog
                open={idleLogoutDialogOpen}
                onClose={() => setIdleLogoutDialogOpen(false)}
                title={loc('loggedOut', lang)}
                message={loc('inactiveLogOut', lang)}
            />

            <CustomSnackbar
                open={snackbarOpen}
                variant={snackbarVariant}
                message={snackbarMessage}
                duration={snackbarDuration}
                onClose={() => handleSnackbar()}
            />

            <ConfirmDialog
                open={confirmDialogOpen}
                message={confirmMessage}
                warning={confirmWarning}
                confirmString={confirmString}
                confirmStringComparisonValue={confirmStringComparisonValue}
                enableConfirm={enableConfirm}
                callbackFunc={callbackFunc}
                onCloseConfirmDialog={handleCloseConfirmDialog}
                onChangeString={handleChangeString}
                confirmButtonText={confirmButtonText}
                cancelButtonText={cancelButtonText}
            />
            <CustomDebugDialog open={debugDialogOpen} json={debugDialogJSON} onClose={handleCloseDebugDialog} />
            <UpdateRequiredDialog
                open={showRefreshDialog}
                disabling={false}
                onClose={() => setShowRefreshDialog(false)}
            />
            <ZohoWidget show={showZohoHelpDesk} auth={auth} />
        </MuiThemeProvider>
    );
};

const LocalizedApp = () => {
    let localStorageLang = window.localStorage.getItem('lang');
    if (
        _.isNil(localStorageLang) ||
        _.isEmpty(localStorageLang) ||
        !AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].includes(localStorageLang)
    ) {
        localStorageLang = DEFAULT_LANG;
    }
    const [lang, setLang] = useState(localStorageLang);
    const handleLang = lang => {
        if (!AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].includes(lang)) {
            return;
        }

        window.localStorage.setItem('lang', lang);
        setLang(lang);
    };
    const AppWithGoogleApi = useCallback(
        GoogleApiWrapper({
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
            libraries: ['places'],
            language: lang
        })(App),
        [lang]
    );

    return (
        <LocalizationProvider value={{ lang, setLang: handleLang }}>
            <AppWithGoogleApi handleLang={handleLang} />
        </LocalizationProvider>
    );
};

export default LocalizedApp;

const ZohoWidget = ({ show, auth }) => {
    // This is pretty hacky but options are very limited for handling this situation
    // Most reliable alternative is to move zoho widget wherever it's actually used (which is everywhere but operator site) but its very messy
    // This is also not future proof as the script may load a component with a different id in the future...

    const renderWidget = () => {
        const doc = document.getElementById('zohohc-asap-web-helper-main');
        if (doc) {
            doc.style.display = '';
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://desk.zoho.com/portal/api/web/inapp/593559000008635023?orgId=746931224';
        script.async = true;

        document.head.appendChild(script);
    };

    const removeWidget = () => {
        const doc = document.getElementById('zohohc-asap-web-helper-main');
        if (!doc) {
            return;
        }

        doc.style.display = 'none';
    };

    useEffect(() => {
        if (!show || _.isNil(auth.isAuthenticated)) {
            removeWidget();
            return;
        }
        if (auth.isAuthenticated && auth.accountType != 'Customer') {
            removeWidget();
            return;
        }

        renderWidget();

        return () => {
            removeWidget();
        };
    }, [show, auth]);

    return <></>;
};

//For some reason adding the zoho widget sets the safe-area-inset-top value to 0px
//this causes content to get cut off on screens with notches and cutouts
//this function sets another css variable --custom-safe-area-top so that it can store safe-area-inset-top before the zoho widget loads
function setCustomSafeAreaTop(count = 0) {
    // Get the safe-area-inset-top value.
    const safeAreaInsetTop = getComputedStyle(document.documentElement).getPropertyValue('--sat');
    //if this function is run too early after the page load then safe-area-inset-top is 0px, keep retrying this function until its a non zero value
    if (_.isEmpty(safeAreaInsetTop) || (safeAreaInsetTop === '0px' && count <= 100)) {
        setTimeout(() => setCustomSafeAreaTop(count + 1), 100);
    } else {
        document.documentElement.style.setProperty('--custom-safe-area-top', safeAreaInsetTop);
    }
}
