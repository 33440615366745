import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';

function useAppliedFeesFilters() {
    const http = useContext(HttpContext);

    const [allCollectors, setAllCollectors] = useState([]);
    const [collectorsSelected, setCollectorsSelected] = useState([]);

    const [allDrivers, setAllDrivers] = useState([]);
    const [driversSelected, setDriversSelected] = useState([]);

    const [allFeeTypes, setAllFeeTypes] = useState([]);
    const [feeTypesSelected, setFeeTypesSelected] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getAllDrivers');
            if (res.ok) {
                let drivers = _.get(res, 'data.drivers', []);
                setAllDrivers(drivers);
                setDriversSelected(drivers.map(driver => driver._id));
            }
            const res2 = await http.getJSON('/collectors');
            if (res2.ok) {
                let collectors = _.get(res2, 'data.collectors', []);
                setAllCollectors(collectors);
                setCollectorsSelected(collectors.map(collector => collector._id));
            }
            const res3 = await http.getJSON('/bulks/CustomFeeTypes');
            if (res3.ok) {
                let feeTypes = _.get(res3, 'data.feeTypes', []);
                setAllFeeTypes(feeTypes);
                setFeeTypesSelected(feeTypes.map(feeType => feeType._id));
            }
        })();
    }, []);

    const handleAllFilterCollectors = all => e => {
        e.stopPropagation();
        if (all) {
            setCollectorsSelected(allCollectors.map(c => c._id));
        } else {
            setCollectorsSelected([]);
        }
    };

    const handleAllFilterFeeTypes = all => e => {
        e.stopPropagation();
        if (all) {
            setFeeTypesSelected(allFeeTypes.map(f => f._id));
        } else {
            setFeeTypesSelected([]);
        }
    };

    return {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allDrivers,
        driversSelected,
        setDriversSelected,
        allFeeTypes,
        feeTypesSelected,
        setFeeTypesSelected,
        handleAllFilterCollectors,
        handleAllFilterFeeTypes
    };
}

export default useAppliedFeesFilters;
