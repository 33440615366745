import React, { useState } from 'react';

import _ from 'lodash';

import { Chip, Typography, Icon, colors, Tooltip, withTheme } from '@material-ui/core';

import { loc, locDate } from 'localizations/localizationHandler';

import { isEXPRegion, truncateBagtag } from 'utils/misc';

import MDIcon from '@mdi/react';
import { mdiTagArrowDown, mdiLabel, mdiWrench, mdiTagCheck, mdiTagPlus } from '@mdi/js';
import { darkTheme } from 'theme';

function Bagtags({ customer, bagtags, sessions, collector, lang, theme, showEmptyBagTag = true }) {
    const customerId = customer.uniqueID;
    const sessionsWithValidLabels = _.filter(sessions, session => session.bagtag && session.bagtag !== customerId);

    const allUniqueBagTags = _.uniqBy(bagtags, 'bagtag');

    if (!_.isEmpty(sessionsWithValidLabels)) {
        return (
            <ShowHideBagTagList theme={theme} lang={lang} bagTagObj={sessionsWithValidLabels} collector={collector} />
        );
    } else {
        return (
            <ShowHideBagTagList
                theme={theme}
                lang={lang}
                bagTagObj={allUniqueBagTags}
                customerId={customerId}
                showEmptyBagTag={showEmptyBagTag}
                collector={collector}
            />
        );
    }
}

export default withTheme()(Bagtags);

function ShowHideBagTagList({ theme, lang, bagTagObj, customerId, showEmptyBagTag, collector }) {
    const [showMore, setShowMore] = useState(true);

    return (
        <>
            {bagTagObj.map((obj, i) => {
                if (!showMore && i > 0) {
                    return <></>;
                }

                if (!showEmptyBagTag) {
                    if (_.isEmpty(obj.bagtag)) {
                        return <></>;
                    }
                }

                var isCustomerLabel = truncateBagtag(obj.bagtag) === customerId;

                if (isCustomerLabel) {
                    return (
                        <div
                            style={{
                                height: 'auto',
                                width: '100%',
                                overflow: 'auto'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <MDIcon
                                    path={mdiLabel}
                                    size={0.9}
                                    color={theme.palette.text.secondary}
                                    style={{ margin: theme.spacing.unit / 2 }}
                                />
                                <Typography color="textSecondary">
                                    <b>{truncateBagtag(obj.bagtag)}</b> x {obj.commoditiesProcessed} {loc('bags', lang)}{' '}
                                    {obj.dateCounted && locDate(obj.dateCounted, 'MMM D, YY h:mmA', lang)}
                                </Typography>
                            </div>
                        </div>
                    );
                }

                return (
                    <div
                        style={{
                            height: 'auto',
                            width: '100%',
                            overflow: 'auto'
                        }}
                    >
                        <BagTagWithDate
                            theme={theme}
                            lang={lang}
                            bagTagObj={obj}
                            isCustomerLabel={isCustomerLabel}
                            collector={collector}
                        />
                    </div>
                );
            })}

            {bagTagObj.length > 1 && !showMore && (
                <Typography
                    style={{
                        textAlign: 'right',
                        display: 'block',
                        color: theme.palette.primary.main,
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowMore(true)}
                >
                    {loc('showAll', lang)}
                </Typography>
            )}
            {bagTagObj.length > 1 && showMore && (
                <Typography
                    style={{
                        textAlign: 'right',
                        display: 'block',
                        color: theme.palette.primary.main,
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowMore(false)}
                >
                    {loc('showLess', lang)}
                </Typography>
            )}
        </>
    );
}

function BagTagWithDate({ theme, lang, bagTagObj, collector }) {
    const { bagtag, dateCounted, operator } = bagTagObj;
    const collectorCodeFallback = _.get(collector, 'code', 'N/A');
    const bagtagCollectorCode = isEXPRegion()
        ? _.get(bagTagObj, 'expressVariables.CounterCode', collectorCodeFallback)
        : _.get(bagTagObj, 'tomraVariables.locationName', collectorCodeFallback);
    const tooltipText =
        (dateCounted ? 'Processed on ' + locDate(dateCounted, 'MMM D, YYYY hh:mm A', lang) : 'Processed') +
        ' by ' +
        bagtagCollectorCode;

    return (
        <Tooltip title={tooltipText}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <MDIcon
                        path={mdiTagPlus}
                        size={0.8}
                        color={theme.palette.text.secondary}
                        style={{ margin: theme.spacing.unit / 2 }}
                    />
                    <Typography color="textSecondary" style={{ fontSize: '0.8rem' }}>
                        <b>{truncateBagtag(bagtag)}</b>{' '}
                        {dateCounted && locDate(dateCounted, 'MMM D, YY h:mmA', lang) + ', '} {bagtagCollectorCode}
                    </Typography>
                    {dateCounted && (
                        <span
                            style={{
                                marginRight: theme.spacing.unit,
                                marginLeft: theme.spacing.unit
                            }}
                        >
                            <Icon style={{ color: colors.green[500], fontSize: '18px' }}>check</Icon>
                        </span>
                    )}
                </div>
            </div>
        </Tooltip>
    );
}
